.Content .LeftMenu {
  width: 20%;
  background-color: #1c318380;
  margin-right: 5px;
  position: relative;
  display: none;
}

.Content .LeftMenu>div {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
  padding-left: 10px;
  padding-right: 10px;
}

.Content .LeftMenu h3 {
  color: #dbe3e4;
  text-align: center;
  text-shadow: 0px 0px 4px black;
  letter-spacing: 2px;
}

.Content .LeftMenu .Trakk {
  background-color: #a7b5b7;
  margin-top: 15px;
  height: 150px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px black;
}

.Content .LeftMenu .Trakk:last-child {
  background-color: #a7b5b7;
  margin-top: 15px;
  margin-bottom: 15px;
}