.ModalDialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  top: 0;
  left: 0;
}

.ModalDialogContainer {
  max-width: 400px;
  height: auto;
  background-color: white;
  margin: -2rem auto;
  animation-name: ModalPopupDialogContainer;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  padding: 20px;
}

@keyframes ModalPopupDialogContainer {
  from {
    margin: -7rem auto;
  }

  to {
    margin: 5rem auto;
  }
}