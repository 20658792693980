.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  & .message {
    background-color: white;
    width: fit-content;
    align-self: center;
    padding: 20px;
    font-size: 1.3rem;
    border-radius: 5px;
    box-shadow: 2px 2px 6px 0px black;

    & span {
      display: block;
    }

    & span:not(:last-child) {
      margin-bottom: 10px;
    }

    & .get-started {
      margin-top: 25px;
    }

    & .get-started, & .click-here {
      text-align: right;
    }
  }
}