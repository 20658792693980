.gvd-validation-search {
  display: flex;
  flex-direction: row;

  & .description {
    display: block;
    flex-grow: 1;
    font-weight: bold;
    align-self: center;
  }

  & .search-container {
    & input.brick-input {
      margin-right: 10px;
      padding: 5px;
      outline: none;
      border: none;
      border: 1px solid #707070;
    }

    & button.brick-search-btn {
      padding: 5px;
      border: 1px solid #707070;
      background-color: #707070;
      color: white;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.gvd-validation-body {
  flex-grow: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  & > button {
    cursor: pointer;
  }

  & > input {
    padding: 5px;
    outline: none;
    border: 1px solid #707070;
  }

  & .container-body {
    padding: 5px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 0;
    overflow-y: auto;
    position: relative;

    & .gvd-list-wrapper {
      flex-grow: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      overflow-y: auto;
    }
  }

  @media (max-width: 935px) {
    & .container-body {
      min-height: 180px;
    }
  }

  & .container-title {
    padding: 5px;
    border-bottom: 1px solid #707070;
    display: flex;

    &.column {
      flex-direction: column;
    }

    &.row {
      flex-direction: row;
      align-items: center;

      & > .right-note {
        font-size: 14px;
        font-style: italic;
      }
    }

    & > .title {
      font-weight: bold;
    }

    & .raw-words-search-container {
      flex-grow: 1;
      margin-left: 20px;
      margin-right: 20px;

      & > form input {
        width: 100%;
        outline: none;
        border: 1px solid #707070;
        padding: 3px;
      }
    }

    & > .sub-title {
      margin-top: 5px;
      font-size: 12px;
      font-style: italic;
    }
  }

  & .left-side {
    background-color: white;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid #707070;
    width: 60%;
  }

  & .right-side {
    margin-left: 5px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 0;

    & > div {
      background-color: white;
      border: 1px solid #707070;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:not(.gvd-assign-create) {
        flex-grow: 1;
      }
    }

    & .gvd-assign-create .container-body {
      flex-direction: row;
      align-self: center;
      padding: 10px;
      height: auto;
    }

    & .gvd-selection-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 10px 5px;
      align-items: center;

      & .gvd-label {
        font-weight: bold;
        margin-right: 10px;
        text-align: right;
      }

      & .gvd-dropdown {
        margin-right: 10px;
        padding: 5px;
        outline: none;
        border: none;
        border: 1px solid #707070;
      }

      & .gvd-new-btn {
        padding: 5px;
        border: 1px solid #707070;
        background-color: #707070;
        color: white;
        font-size: 12px;
      }
    }

    & .gvd-assign-cancel-container {
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-left: 15px;

      & .gvd-assign-btn {
        padding: 7px 15px;
        border: 1px solid #707070;
        background-color: #707070;
        color: white;
        font-size: 12px;
        margin-bottom: 20px;
      }

      & .gvd-cancel-btn {
        padding: 7px 15px;
        border: 1px solid #707070;
        font-size: 12px;
      }
    }

    @media (max-width: 1260px) {
      & .gvd-assign-create .container-body {
        flex-direction: column;
      }

      & .gvd-assign-cancel-container {
        flex-direction: row;
        margin: 0px;
        margin-top: 20px;

        & .gvd-assign-btn {
          margin: 0px;
          margin-right: 20px;
        }
      }
    }

    & .gvd-details .gvd-details-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: fit-content;
      font-size: 14px;

      & .gvd-item-description {
        padding: 5px 10px;
        font-weight: bold;

        &.selected {
          background-color: #cce4dc !important;
        }

        &,
        & * {
          user-select: none;
        }
      }

      & .gvd-item-sub {
        margin-top: 5px;
        margin-left: 15px;
      }
    }
  }

  @media (max-width: 935px) {
    flex-direction: column;

    & .left-side,
    & .right-side {
      margin: 0;
      width: 100%;
    }

    & .left-side {
      margin-bottom: 10px;
    }
  }

  .gvd-words-list {
    & .gvd-word {
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      &:nth-child(odd) {
        background-color: #cce4dc;
      }

      &:hover,
      &.selected {
        background-color: #707070;
        color: white;

        & svg {
          color: white;
        }
      }

      &.selected {
        background-color: #1180d5;
      }

      & > *:not(:last-child) {
        margin-right: 10px;
      }

      & .gvd-word-description {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
