.get-started-account-type {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 0px;

  & .account-type-container:not(:nth-last-child(2)) {
    margin-bottom: 40px;
  }

  & .account-type-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    & .account-type-title {
      max-width: 100px;
      display: flex;
      width: 100px;
      align-items: center;

      & * {
        margin-right: 15px;
      }
    }

    & .account-type-description {
      flex-grow: 1;
      margin-left: 15px;
    }
  }

  & .choose-account-type {
    color: red;
  }

  & .message {
    margin-bottom: 30px;
  }

  & .action-footer {
    align-self: center;

    & button {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    & button:not(:last-child) {
      margin-right: 10px;
    }
  }
}