.gvd-words-list.products-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px 15px;
  align-items: center;
  font-size: 14px;

  & span.brand, & span.description {
    font-weight: bold;
  }
}