.ProductScanContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProductScanContainer p {
  font-weight: bold;
  font-size: 1.3rem;
}

.ProductScanContainer input.GTIN {
  min-width: 250px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 0.3rem;
  border: none;
  border-radius: 5px;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.ProductScanContainer hr {
  background-color: black;
  height: 0.1rem;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ProductScanContainer .Result p {
  margin: 0;
}

.ProductScanContainer table.RecordDetail {
  background-color: #d0dcd1;
  margin-bottom: 1rem;
}

.ProductScanContainer table.RecordDetail td {
  text-align: left;
  padding: 16px;
}

.ProductScanContainer table.RecordDetail tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ProductScanResult {
  display: flex;
  flex-direction: column;
  width: 85%;
  border: 1px solid grey;
}

.ProductScanResult .Row {
  flex-grow: 1;
  display: flex;
}

.ProductScanResult .Row:not(:last-child) {
  border-bottom: 1px solid grey;
}

.ProductScanResult .Column {
  flex-grow: 1;
  padding: 5px;
  width: 0;
}

.ProductScanResult .Column:not(:last-child) {
  border-right: 1px solid grey;
}

.ProductScanResult .Column .Header {
  font-weight: bold;
  border-bottom: 1px solid grey;
}

.ProductScanResult .Column .Content {
  margin: 0;
  margin-top: 10px;
  flex-direction: column;
}

.ProductScanCompromisedForm {
  margin-top: 10px;
}

.ProductScanCompromisedForm input.GTIN {
  min-width: 250px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 0.3rem;
  border: none;
  border-radius: 5px;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.ProductScanCompromisedForm .Search {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.ProductScanCompromisedForm .Loading {
  display: block;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
}

.ProductScanCompromisedForm .ErrorMsg {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: red;
  font-weight: bold;
  font-style: italic;
}

.ProductScanCompromisedForm .AddNewCompany {
  margin-left: 10px;
  cursor: pointer;
}

.ProductScanCompromisedForm .ParentCompanyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  height: 300px;
  border-width: 1px;
  border-color: "red";
  background-color: "red";
}

.ProductScanCompromisedForm .ParentCompanyContainer span {
  margin-left: 5px;
  font-size: 14px;
  color: aqua;
}
