@tailwind base;
@tailwind components;
@tailwind utilities;

.Ul_li--hover::before {
  content: "";
  background-color: #032158;
  position: absolute;
  height: 36px;
  width: 225px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  opacity: 0.1;
  left: -233px;
  transition: all 400ms ease;
}

.Ul_li--hover:hover::before {
  left: 0px;
  transition: all 300ms ease;
  z-index: -1;
}

.metismenu .has-arrow:after {
  right: 2em !important;
  opacity: 0.6 !important;
  width: 0.6em !important;
  height: 0.6em !important;
  border-width: 2px 0 0 2px !important;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

/* width */
::-webkit-scrollbar {
  width:5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}