.dashboard-get-started {
  justify-content: center;
  flex-grow: 1;
  display: flex;
  margin-top: 50px;
}

.form {

  & table {
    & td {
      padding-bottom: 10px;
    }

    & .form-invalid {
      color: red;
    }

    & .action-footer {
      text-align: center;

      &.right {
        text-align: right ;
      }
    }
  }

  & input[type="text"], & input[type="number"], & textarea {
    padding: 5px;
    width: 100%;

    &.required {
      border: 1px solid red;

      &:focus {
        outline: 1px solid red;
      }
    }
  }

  & button {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & button:not(:last-child) {
    margin-right: 10px;
  }

  & label {
    margin-right: 10px;
  }

  & label:after {
    content: ":";
  }

  & label.no-colon::after {
    content: none;
  }
}