* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div#root {
  height: 100%;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100%;
  & > .side-menu {
    background-color: #032158;
    display: flex;
    flex-direction: column;
    width: 310px;
    max-height: 100vh;
    color: white;
    overflow-y: auto;
    & .logo {
      width: 250px;
      height: 250px;
      margin: 0 auto;
    }
    & .items-container {
      width: 100%;
      min-width: fit-content;
      margin-top: 25px;
      & .items {
        background-color: #00205b;
        padding: 10px;
        display: flex;
        flex-direction: column;
        & a {
          font-family: Roboto, Regular;
          font-size: 16px;
          color: #ededed;
          opacity: 1;
          &:not(:first-child) {
            margin-top: 8px;
          }
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
        & a + .item {
          margin-top: 8px;
        }
        & .item {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #ededed;
          padding-left: 12px;
          &:not(:first-child) {
            margin-top: 7px;
          }
          &:not(:last-child) {
            padding-bottom: 7px;
          }
          &:last-child {
            border-bottom: none;
          }
          &.is-selected,
          & .item-link.is-selected,
          &.is-selected a,
          &.is-selected span,
          & .item-link.is-selected a,
          .item-link.is-selected span {
            background: white;
            color: black;
          }
          & .item-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            & a {
              flex-grow: 1;
              margin: 0;
            }
            & svg.link-icon-status {
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
    & .footer ul {
      padding: 15px;
      & li {
        list-style-type: none;
      }
      & li:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  & > .container {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    padding: 20px;
    width: 0;
    overflow-y: auto;
    max-height: 100vh;
    & > .header {
      margin-bottom: 20px;
      & h1 {
        font-family: "Roboto";
        text-align: center;
      }
    }
    & > .body {
      flex-grow: 1;
      background-color: #ededed;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      padding: 15px;
    }
  }
  & .request-processing-indicator {
    position: relative;
    & .request-processing {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000c2;
      display: flex;
      align-items: center;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      & > span {
        text-align: center;
        color: white;
        font-weight: bold;
        flex-grow: 1;
        user-select: none;
      }
    }
  }
  & > .dashboard-popup-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #00000080;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    & .dashboard-popup-container {
      max-height: 70%;
      width: 500px;
      background-color: white;
      display: flex;
      flex-direction: column;
      & .dashboard-popup-header {
        padding: 10px;
        color: black;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #707070;
        & .header {
          font-weight: bold;
          margin-right: 10px;
        }
        & .description {
          flex-grow: 1;
          margin-right: 15px;
          font-size: 12px;
        }
        & .close {
          cursor: pointer;
        }
        & .header-styling {
          align-items: flex-start;
          border-width: 1;
          // background-color: red;
        }
      }
      & .dashboard-popup-body-container {
        // width: 600px;
        overflow-y: auto;
        & > div.body {
          padding: 10px;
          overflow-y: auto;
          height: 100%;
          // width: 600px;
        }
      }
    }
  }
}
