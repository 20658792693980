.Container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Content {
  margin-top: 5px;
  flex-grow: 1;
  display: flex;
}

.Body {
  display: block;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.Content .Body {
  flex-grow: 1;
}