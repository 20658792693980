.gvd-words-list.ignore-substitute {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 20px 15px;
  align-items: center;
  font-size: 14px;

  & span.ignore,
  & span.substitute {
    font-weight: bold;
    text-align: center;
    font-size: 15px;
  }

  & span.substitute {
    grid-column-start: 3;
    grid-column-end: 6;
  }

  & .word-description,
  & .word-ignore-checkbox,
  & .word-substitute-input {
    margin: 0 auto;
  }

  & .word-ignore-checkbox {
    width: 23px;
    height: 23px;
  }

  & .word-substitute-input {
    padding: 3px;
    outline: none;
    border: none;
    border: 1px solid #707070;
    width: 100px;
  }

  & .confirm-btn {
    padding: 7px 15px;
    border: 1px solid #707070;
    background-color: #707070;
    color: white;
    font-size: 12px;
    grid-column-start: 1;
    grid-column-end: 6;
    margin: 0 auto;
    cursor: pointer;
  }
}