.get-started-account-type.supplier {

  & .terms-conditions-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  & .act-behalf-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &>input {
      margin-right: 20px;
    }
  }

  & .company-name {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    font-size: 1.3rem;
  }

  & .confirming {
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
  }

  & .terms-not-checked {
    font-weight: bold;
    font-style: italic;
    text-align: center;
    color: red;
  }

  & .message {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  & .action-footer {
    margin-top: 10px;
  }
}