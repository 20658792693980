a {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #1c3183;
  text-decoration: none
}


.sidemenu-toggle{
  left: -240px !important;
}

.maincontent-toggle{
  width: 100% !important;
}