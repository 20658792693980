.metismenu .arrow {
    float: right;
    line-height: 1.42857
}

[dir=rtl] .metismenu .arrow {
    float: left
}

.metismenu .glyphicon.arrow:before {
    content: "\e079"
}

.metismenu .mm-active>a>.glyphicon.arrow:before {
    content: "\e114"
}

.metismenu .fa.arrow:before {
    content: "\f104"
}

.metismenu .mm-active>a>.fa.arrow:before {
    content: "\f107"
}

.metismenu .ion.arrow:before {
    content: "\f3d2"
}

.metismenu .mm-active>a>.ion.arrow:before {
    content: "\f3d0"
}

.metismenu .plus-times {
    float: right
}

[dir=rtl] .metismenu .plus-times {
    float: left
}

.metismenu .fa.plus-times:before {
    content: "\f067"
}

.metismenu .mm-active>a>.fa.plus-times {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.metismenu .plus-minus {
    float: right
}

[dir=rtl] .metismenu .plus-minus {
    float: left
}

.metismenu .fa.plus-minus:before {
    content: "\f067"
}

.metismenu .mm-active>a>.fa.plus-minus:before {
    content: "\f068"
}

.metismenu .mm-collapse:not(.mm-show) {
    display: none
}

.metismenu .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility
}

.metismenu .has-arrow {
    position: relative
}

.metismenu .has-arrow:after {
    position: absolute;
    content: "";
    width: .5em;
    height: .5em;
    border-style: solid;
    border-width: 1px 0 0 1px;
    border-color: initial;
    right: 1em;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    -webkit-transform-origin: top;
    transform-origin: top;
    top: 50%;
    transition: all .3s ease-out
}

[dir=rtl] .metismenu .has-arrow:after {
    right: auto;
    left: 1em;
    -webkit-transform: rotate(135deg) translateY(-50%);
    transform: rotate(135deg) translateY(-50%)
}

.metismenu .has-arrow[aria-expanded=true]:after,
.metismenu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%)
}

[dir=rtl] .metismenu .has-arrow[aria-expanded=true]:after,
[dir=rtl] .metismenu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(225deg) translateY(-50%);
    transform: rotate(225deg) translateY(-50%)
}