.Navbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  height: 60px;
  background-color: #d0dcd1;
  box-shadow: 0px 0px 5px 1px black;
}

.Navbar.Sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.Navbar .Logo {
  width: 130px;
  margin: 0;
  padding: 0;
  margin-right: 15px;
}

.Menu {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
}

.Menu a {
  height: inherit;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.Menu a .LinkEffect {
  height: 2px;
  width: 100%;
  background-color: black;
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: bottom 0.3s;
}

.Menu a:hover {
  background-color: #708e951f;
}

.Menu a:hover .LinkEffect {
  bottom: 0;
}

.Menu ul {
  padding: 0;
  margin: 0;
}

.Menu>li {
  height: 60px;
}

.Menu li {
  display: block;
  float: left;
  transition-duration: 0.5s;
}

.Menu li:hover {
  cursor: pointer;
}

.Menu li ul {
  visibility: hidden;
  position: absolute;
  transition: all 0.5s ease;
  background-color: #d0dcd1;
  display: none;
  z-index: 1;
  box-shadow: 0px 6px 6px -1px black;
}

.Menu li ul li.SubMenu {
  /* background-image: url('/menu_sub_menu.svg'); */
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

.Menu li ul li ul {
  left: 100%;
  box-shadow: 4px 6px 6px -1px black;
}

.Menu li:hover>ul,
.Menu li ul:hover {
  visibility: visible;
  display: block;
}

.Menu li ul li {
  clear: both;
  width: 100%;
  min-height: 45px;
  display: flex;
}

.Menu li ul li a {
  flex-grow: 1;
  white-space: nowrap;
}

.SearchBox {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.SearchBox input[type="text"],
.SearchBox input[type="text"]:focus {
  width: 100%;
  border: 0;
  padding: 0;
  outline: none;
  background: none;
}

.UserName {
  animation-name: UserName;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0.5s;
}

.UserName .Menu ul {
  right: 0;
}

@keyframes UserName {

  from {
    transform: translateY(-100vh);
  }

  top {
    transform: translateY(0vh);
  }

}