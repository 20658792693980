.iei-container {
  margin: 0 auto;
  width: 80%;

  & > .overview {
    line-height: 1.5;
  }
  & > .title {
    line-height: 3;
    text-align: center;
  }
  & > .heading {
    line-height: 2;
    text-align: center;
    color: "#00205b";
  }
  & > .video {
    text-align: center;
  }

  & > div {
    margin-bottom: 20px;
  }

  & .tablestyle {
    border: 0px solid #999;
  }

  & .rowstyle {
    border: 0px solid #999;
  }

  & .cellstyle {
    border: 0px solid #999;
    padding: 15px;
  }

  & .smalltext {
    line-height: 1;
  }
}
