.BricksContainer {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
} 

.BrickSearchContainer {
  padding: 10px;
  background-color: #f2f2f2;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.BrickSearchContainer #brick_search {
  padding: 5px;
  margin-left: 10px;
  width: 250px
}

.BrickSearchContainer #brick_search:focus {
  outline: none;
}

.BrickSearchContainer button {
  margin-left: 10px;
  padding: 5px;
}

.FunctionalGroupComp:hover, .FunctionalGroupVarComp:hover {
  background-color: #bbbbbb !important;
}

.FunctionalGroupVarComp {
  display: flex;
  padding: 7px;
  align-items: center;
  background-color: #d0dcd1;
  margin-bottom: 1px;
  cursor: pointer;
}