.DataTableContainer {
  flex-grow: 1;
  overflow: auto;
}

.DataTable {
  background-color: #d0dcd1;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.DataTable thead {
  font-weight: bold;
}

.DataTable thead th {
  position: sticky;
  top: 0;
  background-color: #d0dcd1;
}

.DataTable th,
.DataTable td {
  text-align: left;
  padding: 13px;
}

.DataTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.DataTable.ClickableRow tbody tr:hover {
  cursor: pointer;
  background-color: #bbbbbb;
}

.DataTable.ClickableRow tbody tr.Selected {
  background-color: #337d63;
  color: white;
}

.ActionFooter {
  display: flex;
  align-self: center;
}