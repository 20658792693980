.NotFound {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotFound h3 {
  text-align: center;
  color: black;
}